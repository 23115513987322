import React from "react";
import {option} from "react-timekeeper/lib/components/styles/time-dropdown";
import {useSelector} from "react-redux";
import FeatherIcon from "feather-icons-react";
import {useTranslation} from "react-i18next";

export default function ToggleLayout({
                                       title,
                                       image,
                                       toggleIndex,
                                       visibleToggleIndex,
                                       onVisibleToggleIndexChange,
                                       children,
                                       dropDown,
                                       onPlotChange,
                                       Farm,
                                       kit,
                                       value,
                                       imgType,
                                       feather,
                                       featherclass,
                                       print
                                     }) {
  // const [isExpand, setIsExpand] = useState(false);
  const plotList = useSelector(state => {
    return state.plotList.plotList
  });

  const {t, i18n} = useTranslation();
  const onPlotSelect = (e) => {
    onPlotChange(e.target.value);
  }

  return (
    <div className="single-card m-rl-m-8">
      <div onClick={() => onVisibleToggleIndexChange(toggleIndex)}
           className={"row sa-cursor align-items-center px-3  " + (toggleIndex === visibleToggleIndex ? 'toggle-border-bottom' : '')}
           style={{height: '64px'}}>
        <div className={"col-auto"}>
          <div className={'image-container-toggle mr-3'}>
            {imgType === "img" && <img src={image} width={'24px'}
                                       className={toggleIndex === visibleToggleIndex ? 'toggle-icon-active' : null}/>}
            {imgType === "feather" && <FeatherIcon icon={feather}
                                                   className={featherclass + ' ' + (toggleIndex === visibleToggleIndex ? 'toggle-active-feather' : null)}/>}
          </div>
          <div
            className={'toggle-title ' + (toggleIndex === visibleToggleIndex ? 'toggle-text-green' : 'text-dark-gray')}>{title}</div>
        </div>
        <div className={"ml-auto d-flex align-items-center col-auto"}>

          {!print && <div className={'toggle-btn-container'}>
            <i className={"fa " + (toggleIndex === visibleToggleIndex ? "fa-angle-up" : "fa-angle-down")}
               color={'#91949E'}/>
          </div>}
        </div>
      </div>
      {toggleIndex === visibleToggleIndex && dropDown &&
        <select onChange={onPlotSelect} className={`form-control hide-768  toggle-layout-select align-items-center`}
                placeholder="Plot Name" style={{width: '228px'}} value={value}>
          <option value="" hidden>{t("title.SELECT_A_SECTOR")}</option>

          {Farm && <option value={"FARM"}>{t("title.FARM")}</option>}

          {plotList.map((plot, index) => (
            <option key={"asdas" + plot.id} name={plot.name} value={plot.id}>{plot.name}</option>
          ))}
          {kit && 
            [...new Set(plotList.map(plot => plot.kitId))]
              .filter(kitId => kitId) 
              .map((kitId, index) => (
                <option key={kitId + index} value={kitId}>
                  {kitId}
                </option>
              ))
          }
        </select>}


      {toggleIndex === visibleToggleIndex && <div className={'p-a-16'}>
        {toggleIndex === visibleToggleIndex && dropDown &&
          <select onChange={onPlotSelect} className={`form-control align-items-center show-768 mb-3`}
                  placeholder="Plot Name" value={value}>
            <option value="" hidden>{t("title.SELECT_A_SECTOR")}</option>

            {Farm && <option value="FARM">{t("title.FARM")}</option>}

            {plotList.map((plot, index) => (
              <option key={"asdas" + plot.id} value={plot.id}>{plot.name}</option>
            ))}
            {kit && 
              [...new Set(plotList.map(plot => plot.kitId))]
                .filter(kitId => kitId) 
                .map((kitId, index) => (
                  <option key={kitId + index} value={kitId}>
                    {kitId}
                  </option>
                ))
            }
          </select>}
        {children}
      </div>}

    </div>
  )
}

ToggleLayout.defaultProps = {
  Farm: true,
  imgType: "img"
}
